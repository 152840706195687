/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-family: "PT Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
}

section {
  padding-left: 13vw;
  padding-right: 13vw;
  &.first-section {
    position: relative;
    padding-left: 0;
    padding-right: 0;
    line-height: 0;
    overflow: hidden;
    .bg {
      background-position: top center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      position: absolute;
    }
    .block {
      width: 100%;
      position: relative;
      &.block-1 {
        min-height: 100vh;
      }
    }
    .logo-wrapper {
      width: 38vw;
      position: absolute;
      top: 50vh;
      left: 13vw;
      transform: translateY(-50%);
      img {
        max-width: 100%;
      }
      @media (max-width: 1024px) {
        left: 30px;
        width: calc(100% - 60px);
        top: 40%;
      }
    }
    .discovermore {
      position: absolute;
      top: 90vh;
      left: 13vw;
      color: #fff;
      font-style: normal;
      display: block;
      @media (max-width: 1024px) {
        left: 30px;
        top: 80vh;
      }
    }
    .powered {
      position: absolute;
      right: 3vw;
      top: 90vh;
      color: #fff;
      transform: rotate(-90deg) translateX(40%);
      a {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
      }
      @media (min-width: 1025px) {
        position: fixed;
      }
      @media (max-width: 1024px) {
        transform: rotate(0) translateX(0);
        right: auto;
        left: 30px;
        top: 70vh;
      }
    }
    .cont {
      padding-top: 20vh;
      padding-bottom: 20vh;
      position: relative;
      left: 13vw;
      h1 {
        width: 58vw;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      p {
        width: 42vw;
        color: #fff;
        line-height: 1.5em;
        font-size: 20px;
        margin-top: 4vh;
        @media (max-width: 1024px) {
          width: 100%;
          font-size: 16px;
        }
      }
      @media (max-width: 1024px) {
        width: calc(100% - 60px);
        left: 30px;
      }
    }
  }
  &.second {
    background-color: #938774;
    padding-top: 15vh;
    padding-bottom: 15vh;
    h2 {
      width: 66vw;
      @media (max-width: 1024px) {
        width: 100%;
      }
    }
    .ctas {
      margin-top: 8vh;
      width: 100%;
      p {
        color: #fff;
        font-size: 20px;
        display: inline-block;
        margin-right: 30px;
      }
      .btn {
        background-color: #fff;
        display: inline-block;
        height: 70px;
        line-height: 66px;
        padding-left: 90px;
        padding-right: 90px;
        color: #5c4e47;
        border-radius: 35px;
        text-decoration: none;
        font-size: 20px;
        border: 2px solid transparent;
        transition: all 0.3s ease-in-out;
        &:hover {
          background: transparent;
          border-color: #fff;
          color: #fff;
        }
        @media (max-width: 1024px) {
          font-size: 16px;
          padding-left: 30px;
          padding-right: 30px;
          display: block;
          margin-top: 15px;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

h1,
h2 {
  font-size: 50px;
  line-height: 1.2em;
  @media (max-width: 1024px) {
    font-size: 26px;
  }
}

footer {
  padding-top: 170px;
  padding-bottom: 4vh;
  padding-left: 13vw;
  padding-right: 8vw;
  display: flex;
  color: #5c4e47;
  .copy {
    flex-basis: 30%;
    @media (max-width: 1024px) {
      flex-basis: 100%;
      order: 2;
      margin-top: 30px;
    }
  }
  .follow {
    flex-basis: 50%;
    @media (max-width: 1024px) {
      flex-basis: 100%;
      order: 1;
    }
    span {
      font-weight: bold;
      @media (max-width: 1024px) {
        display: block;
      }
    }
    a {
      color: #5c4e47;
      text-decoration: none;
      display: inline-block;
      margin: 0 20px;
      @media (max-width: 1024px) {
        margin: 5px 0;
        display: block;
      }
    }
  }
  .credits {
    flex-basis: 20%;
    text-align: right;
    a {
      color: #5c4e47;
    }
    @media (max-width: 1024px) {
      flex-basis: 100%;
      text-align: left;
      order: 3;
      margin-top: 5px;
    }
  }
  @media (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 60px;
    flex-wrap: wrap;
  }
}
